<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <div class="card mb-g">
            <div class="card-body pb-0 px-4">
                <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
                    <h5 class="mb-0 flex-1 text-dark fw-500">
                        Cập nhật thông tin tài khoản
                    </h5>
                    <span v-if="!confirming" class="text-muted fs-xs opacity-70"> Cập nhật lần cuối {{ timeUpdate }}</span>
                </div>
                <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
                    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
                        <div class="row">
                            <div class="col-md-4 mb-3" v-if="!confirming">
                                <label class="form-label">Mã nhân viên</label>
                                <input disabled type="text" class="form-control" id="code"
                                       placeholder="Mã nhân viên"
                                       autocomplete="false" :value="user.id + 100000">
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Họ và tên</label>
                                <input type="text" class="form-control" id="name" placeholder="Họ và tên"
                                       autocomplete="false" v-model="user.name" disabled>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Giới tính</label>
                                <edutalk-select id="gender" v-model="user.profile.gender" class="w-100"
                                                placeholder="Chọn giới tính">
                                    <edutalk-option
                                        v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.name">
                                    </edutalk-option>
                                </edutalk-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Vị trí/Chức vụ</label>
                                <input type="text" class="form-control" id="account_type" disabled
                                       v-model="user.profile.account_type.name"
                                       placeholder="Vị trí/Chức vụ">
                            </div>
                            <div class="col-md-4 mb-3" v-if="isLeadOrBODorStaff">
                                <label class="form-label">Phòng ban</label>
                                <input type="text" class="form-control" id="department_type" disabled
                                       v-model="user.departmentName"
                                       placeholder="Phòng ban">
                            </div>
                            <div class="col-md-4 mb-3" v-if="isShowBusinessLevel || !isLeadOrBODorStaff">
                                <label class="form-label">Cấp bậc</label>
                                <edutalk-select filterable
                                                class="w-100"
                                                placeholder="Cấp bậc"
                                                v-model="user.profile.business_level_id"
                                                clearable
                                                disabled
                                >
                                    <edutalk-option
                                        v-for="item in businessLevels"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                        <span style="float: left">{{ item.name }}</span>
                                    </edutalk-option>
                                </edutalk-select>
                            </div>
                            <div class="col-md-4 mb-3" v-if="!confirming && !isLeadOrBODorStaff">
                                <label class="form-label">Vị trí kiêm nhiệm</label>
                                <edutalk-select v-model="plurality" class="w-100 el-disabled"
                                                collapse-tags
                                                multiple
                                                placeholder="Vị trí kiêm nhiệm">
                                    <edutalk-option
                                        v-for="item in concurrentOption"
                                        :key="item.id"
                                        clearable="false"
                                        :label="item.name"
                                        :value="item.id"
                                        :disabled="true"
                                        :multiple="true"
                                    >
                                    </edutalk-option>
                                </edutalk-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3" v-if="isHCKVOrGDKV  && !isLeadOrBODorStaff">
                                <label class="form-label">Khu vực làm việc</label>
                                <input v-if="!confirming" type="text" class="form-control"
                                       :value="user.profile.branch  && user.profile.branch.area? user.profile.branch.area.name : ''"
                                       placeholder="" disabled>
                            </div>
                            <div class="col-md-4 mb-3"  v-if="!isLeadOrBODorStaff">
                                <label class="form-label">Văn phòng làm việc</label>
                                <input v-if="!confirming" type="text" class="form-control"
                                       :value="user.profile.branch ? (user.profile.branch.name_alias ? user.profile.branch.name_alias : user.profile.branch.name) : ''"
                                       placeholder="Cơ sở" disabled>
                                <edutalk-select v-else v-model="user.user_positions" class="w-100" clearable filterable
                                                placeholder="Chọn cơ sở làm việc">
                                    <edutalk-option
                                        v-for="item in concurrentOption"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </edutalk-option>
                                </edutalk-select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Thời gian làm việc</label>
                                <input :value="user.profile.start_to_work && dateTime(user.profile.start_to_work)" type="text" disabled class="form-control" id="start_to_work">
                            </div>
                            <div class="col-md-4 mb-3" v-if="isShowMechanism || !isLeadOrBODorStaff">
                                <label class="form-label">Cơ chế thu nhập</label>
                                <edutalk-select v-model="user.profile.salary_mechanism_id" class="w-100"
                                                clearable filterable
                                                placeholder="Chọn cơ chế thu nhập"
                                                disabled
                                >
                                    <edutalk-option
                                        v-for="item in mechanisms"
                                        :key="item.id"
                                        :label="item.name "
                                        :value="item.id"
                                    >
                                    </edutalk-option>
                                </edutalk-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label" for="email">Email</label>
                                <ValidationProvider vid="email" name="Email" rules="required"
                                                    v-slot="{ errors, classes }">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="email">@</span>
                                        </div>
                                        <input type="email" ref="email" v-model="user.email" name="email"
                                               :class="classes" class="form-control" id="email-input"
                                               placeholder="Nhập email của bạn" aria-describedby="email">
                                        <div class="invalid-feedback" data-field="email" data-validator="notEmpty">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Địa chỉ</label>
                                <input v-model="user.profile.address" type="text" class="form-control" id="address"
                                       placeholder="Nhập địa chỉ thường trú">
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Ngày sinh</label>
                                <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                                    v-slot="{ errors, classes }">
                                    <el-date-picker id="birthDay"
                                                    format="dd-MM-yyyy"
                                                    ref="birthday"
                                                    name="birthday" :class="classes"
                                                    value-format="dd-MM-yyyy" type="date" placeholder="Ngày sinh"
                                                    v-mask="'##-##-####'" v-model="user.profile.birthday"
                                                    default-value="10-10-2000"
                                                    style="width: 100%;"
                                    ></el-date-picker>
                                    <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                        {{ errors[0] }}
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Số điện thoại</label>
                                <ValidationProvider vid="phone" name="Số điện thoại"
                                                    :rules="{required: true, min: 10, regex: /((09|03|07|08|05)+([0-9]{8})\b)/g }"
                                                    v-slot="{ errors, classes }">
                                    <div class="input-group">
                                        <the-mask v-model="user.phone" ref="phone" :class="classes"
                                                  placeholder="Nhập số điện thoại" disabled
                                                  class="form-control" id="phone-input" :mask="['##########']"/>
                                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                          <div class="col-md-4 mb-3">
                            <label class="form-label">Số điện thoại liên hệ</label>
                            <ValidationProvider vid="phone" name="Số điện thoại liên hệ"
                                                :rules="{regex: /((09|03|07|08|05)+([0-9]{8})\b)/g }"
                                                v-slot="{ errors, classes }">
                              <div class="input-group">
                                <the-mask v-model="user.phone_other" ref="phone_contact" :class="classes"
                                          placeholder="Nhập số điện thoại liên hệ"
                                          class="form-control" id="phone-input-1" :mask="['##########']"/>
                                <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>
<!--                            <div class="col-md-4 mb-3" v-if="user.phone !==  user.profile.xac_thuc_sdt">-->
<!--                                <label class="form-label" for="email">&ensp;</label>-->
<!--                                <div class="input-group">-->
<!--                                    <button type="button" @click="showModalVerify"-->
<!--                                            v-if="passValidate || (verifyStatus)"-->
<!--                                            class="btn btn-success">Xác minh-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-md-4 mb-3" v-if="user.phone ===  user.profile.xac_thuc_sdt || !getErrors('form')">
                                <label class="form-label" for="email">&ensp;</label>
                                <div class="input-group verify-phone-group">
                                    <div class="verify-phone">
                                        <i class="el-icon-success"></i> <span>Đã xác minh</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Quyền phỏng vấn</label>
                                <div class="d-flex align-items-center" style="height:37.1px">
                                    <i class="fa mr-2 fa-check" aria-hidden="true" :class="
                                        {
                                            'fa-times text-danger' : !user.profile.quyen_phong_van,
                                            'fa-check text-success' : user.profile.quyen_phong_van

                                        }"></i>
                                    {{ user.profile.quyen_phong_van ? 'Đã được cấp quyền' : 'Chưa được cấp quyền ' }}
                                </div>
                            </div>
                        </div>

                        <button :disabled="loading" class="btn btn-primary waves-effect waves-themed" type="submit"><i
                            v-if="loading" class="el-icon-loading"></i> Cập nhật thông tin
                        </button>
                    </form>
                </div>
            </div>
        </div>
    <edutalk-modal :closeOnClickModal="true" width="40%" title="Xác minh số điện thoại"
                   :visible.sync="dialogVerify">
      <h4>Mã xác thực đã gửi về số điện thoại: <b>{{ user.phone }}</b></h4>
      <h5>Bước 1: Kiểm tra tin nhắn "Edutalk" mã xác minh bao gồm 6 chữ số</h5>
      <h5>Bước 2: Tin có định dạng: "EDUTALK: Ma xac thuc cua ban tai web noibo.edutalk.edu.vn la <u>mã xác minh</u></h5>
      <div id="recaptcha-container"></div>
      <div class="input_code" v-if="showInputCode">
        <CodeInput style="width: 100% " :fieldWidth="50" :fieldHeight="50" :loading="false" class="input"
                   v-on:change="onChange" v-on:complete="onComplete"/>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVerify = false">Hủy</el-button>
                <el-button type="success" icon="el-icon-position" :disabled="counting" @click="startCountdown">
                    <vue-countdown v-if="counting" :time="60000" @end="onCountdownEnd" v-slot="{ totalSeconds }">Gửi lại ({{
                        totalSeconds
                      }}s)</vue-countdown>
                    <span v-else @click="sendOTP">Gửi lại</span>
                </el-button>
                <el-button v-loading="loading" v-if="verifyButton" @click="verifyOTP" type="primary"
                           class="btn-primary">Xác nhận</el-button>
            </span>
    </edutalk-modal>
  </ValidationObserver>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import firebase from 'firebase';
import {
  PROFILE_UPDATE_BIZFLY_ID,
  USER_PROFILE,
  USER_UPDATE,
  USER_VALIDATE_PHONE,
  USER_VERIFY_PHONE,
  GET_DEPARTMENT_BY_ID,
  USER_SEND_OTP_BY_PHONE
} from "@/core/services/store/profile.module";
import CodeInput from "vue-verification-code-input";
import VueCountdown from '@chenfengyuan/vue-countdown';
import {mechanisms, mechanisms2} from "@/core/config/mechanism";
import moment from "moment-timezone";

import {
  SALE_EXPERT,
  SALE_INTERN,
  SALE_LEADER,
  SALE_MEMBER,
  GDVP,
  GDKV,
  HANH_CHINH_KHU_VUC,
  STAFF,
  TEAMLEADER,
  BOD
} from "../../../core/config/accountTypeOption";
import {GET_BUSINESS_LEVEL} from "../../../core/services/store/business/level.module";

export default {
  name: "EdutalkInformation",
  components: {
    CodeInput,
    VueCountdown
  },
  props: {
    confirming: Boolean,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      loadingBtn: false,
      user: {
        profile: {
          gender: '',
          birthday: '',
          xac_thuc_sdt: '',
          account_type: {
            name: ''
          }
        },
        user_positions: {},
        gender: '',
        phone: '',
        verified_phone: '',
        phone_other: ''
      },
      HANH_CHINH_KHU_VUC,
      GDKV,
      plurality: null,
      verifyStatus: true,
      dialogVerify: false,
      passValidate: false,
      showInputCode: false,
      verifyButton: false,
      counting: false,
      appVerifier: '',
      timeUpdate: '',
      codeOTP: '',
      options: [
        {name: 'Nam', id: 1},
        {name: 'Nữ', id: 0},
      ],
      firebaseConfig: {
        apiKey: "AIzaSyBuvmAcRr5yMLJu4YSqx9kVP-hTQFPpfYM",
        authDomain: "edutalk-71810.firebaseapp.com",
        databaseURL: "https://edutalk-71810.firebaseio.com",
        projectId: "edutalk-71810",
        storageBucket: "edutalk-71810.appspot.com",
        messagingSenderId: "383005973421",
        appId: "1:383005973421:web:df9086bf9c496c025bdf24",
        measurementId: "G-PZ9XL79R82"
      },
      concurrentOption: [
        {id: 'TPNS', name: 'TP Nhân sự'},
        {id: 'TPOnline', name: 'TP Online'},
        {id: 'TVV', name: 'Tư vấn viên'},
        {id: 'SLX', name: 'SLX'},
        {id: 'TPTT', name: 'TP Truyền thông'},
      ],
      isHCKVOrGDKV: '',
      isShowBusinessLevel: false,
      isLeadOrBODorStaff: false,
      isShowMechanism: false,
      mechanisms: mechanisms,
      businessLevels: [],
      departmentName: ''
    }
  },
  async mounted() {
    await this.getProfileUser();
    await this.getBusinessLevels();
    if (this.errors) {
      this.$refs.form.setErrors(this.errors);
    }
    this.initFirebase();
    if (this.user.profile.business_level_id == 0) {
      this.user.profile.business_level_id = ''
    }
  },
  methods: {
    connectBizfly() {
      let id = this.currentUser.id;
      this.$store.dispatch(PROFILE_UPDATE_BIZFLY_ID, {
        user_id: id
      }).then((data) => {
        this.user.profile.bizfly_id = data.data;
        this.$message({
          type: 'success',
          message: data.message,
          showClose: true
        });
      }).catch((error) => {
        console.log(error)
        this.$message({
          type: 'warning',
          message: error.data.message,
          showClose: true
        });
      });
    },
    dateTime(value) {
      return moment(value).format('DD-MM-YYYY');
    },
    summit() {
      this.passValidate = false;
      // if (this.user.phone !== this.user.profile.xac_thuc_sdt) {
      //   this.verifyStatus = false;
      //   this.passValidate = true;
      //   this.$refs.form.setErrors({phone: ["Bạn cần xác mình số điện thoại mới"]});
      // } else {
        this.loading = true;
        let formData = new FormData();
        this.user.profile.birthday = this.user.profile.birthday.split("-").reverse().join("-");
        formData.append('name', this.user.name);
        formData.append('email', this.user.email);
        formData.append('gender', this.user.profile.gender);
        formData.append('phone', this.user.phone);
        formData.append('account_type', this.user && this.user.profile && this.user.profile.account_type ? this.user.profile.account_type.name : '');
        formData.append('address', this.user.profile.address);
        formData.append('birthday', this.user.profile.birthday);
        formData.append('profile_id', this.user.profile.id);
        formData.append('phone_other', this.user.phone_other);
        this.$store.dispatch(USER_UPDATE, formData)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Cập nhật thông tin tài khoản thành công',
                showClose: true
              });
              this.$refs.form.reset();
              this.loading = false;
              this.changeTimeUpdate();
            }).catch((res) => {
          if (res?.data?.data?.message_validate_form)
            this.$refs.form.setErrors(res.data.data.message_validate_form);
        }).finally(() => {
          this.loading = false;
        });
      // }
    },
    changeTimeUpdate() {
      this.timeUpdate = this.$moment(this.user.updated_at).fromNow();
    },
    async getProfileUser() {
      await this.$store.dispatch(USER_PROFILE).then((res) => {
        this.user = res.data;
        this.user.profile.department_name = res.data.use_department && res.data.use_department.department ? res.data.use_department.department.name : '';
        this.user.profile.birthday = this.user.profile && this.user.profile.birthday ? this.user.profile.birthday.split("-").reverse().join("-") : '';
        this.plurality = this.user.user_positions.map((position) => {
          return position.position;
        });
        this.selectMechanismByArea(this.user);
        this.isShowBusinessLevel = [SALE_LEADER, SALE_MEMBER, SALE_EXPERT, GDKV, GDVP].includes(this.user?.profile.account_type.id);
        this.isHCKVOrGDKV = [SALE_LEADER, SALE_MEMBER, SALE_EXPERT, SALE_INTERN, GDKV, GDVP, HANH_CHINH_KHU_VUC].includes(this.user.profile.account_type.id);
        this.isShowMechanism = [SALE_MEMBER, SALE_INTERN, GDVP, GDKV].includes(this.user.profile.account_type.id);
        this.isLeadOrBODorStaff = [TEAMLEADER, BOD, STAFF].includes(this.user.profile.account_type.id);
      });

      this.changeTimeUpdate();
    },
    async sendOTP() {
      let phone = this.user.phone;
        this.$store.dispatch(USER_SEND_OTP_BY_PHONE, {phone})
            .then((response) => {
                if (response.status === 422) {
                    this.$notify({
                        title: 'Warning',
                        message: response.data.phone[0],
                        type: 'warning'
                    });
                    this.showInputCode = true;
                } else {
                    this.showInputCode = true;
                    this.startCountdown();
                }
            })
    },
    verifyOTP() {
      let otp = this.codeOTP;
      let id = this.currentUser.profile_id;
      let verified_phone = this.user.phone;
      this.loadingBtn = true;
      this.startCountdown();
        this.$store.dispatch(USER_VERIFY_PHONE, {id, verified_phone, otp})
            .then((response) => {
                if (response.status === 422) {
                    this.$notify({
                        title: 'Warning',
                        message: response.data.phone[0],
                        type: 'warning'
                    });
                    this.passValidate = true;
                } else {
                    this.dialogVerify = false;
                    this.user.phone = this.user.profile.xac_thuc_sdt = verified_phone;
                    this.$message({type: 'success', message: `${response.message}`, showClose: true});
                }
                this.loadingBtn = false;
            }).catch((e) => {
                this.passValidate = true;
        });
    },
    initFirebase() {
      if (!firebase.apps.length) {
        firebase.initializeApp(this.firebaseConfig);
      } else {
        firebase.app();
      }
    },
    showModalVerify() {
      let phone = this.user.phone;
      let vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
      if (vnf_regex.test(phone) === false) {
        this.$refs.form.setErrors({phone: ["Số điện thoại có định dạng không đúng"]});
      }
      this.verifyStatus = false;
      this.$store.dispatch(USER_VALIDATE_PHONE, {phone})
          .then((response) => {
            if (response.status === 422) {
              this.passValidate = true;
              this.$refs.form.setErrors(response.data.data);
            } else {
              this.dialogVerify = true;
              setTimeout(() => {
                this.sendOTP();
              }, 0);
            }
          })
          .catch((e) => {
            this.passValidate = true;
            this.$notify({
              title: 'Warning',
              message: e,
              type: 'warning'
            });
          });
    },
    onChange() {
      this.verifyButton = false;
    },
    onComplete(v) {
      this.verifyButton = true;
      this.codeOTP = v;
    },
    startCountdown: function () {
      this.counting = true;
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
    getErrors(scope) {
      try {
        const errors = this.$refs[scope].errors
        let parsed = []
        Object.keys(errors).map((key) => {
          parsed.push(errors[key][0])
        });
        return parsed
      } catch {
        return false
      }
    },
    selectMechanismByArea(user) {
      let area_id = user?.profile.khuvuc_id;
      let account_type_id = user?.profile.account_type.id;
      let candidate_type = user?.profile.candidate.type
      
      if ([9, 13].includes(area_id)) {
        this.mechanisms = mechanisms;
        return;
      }
      
      this.mechanisms = mechanisms2;

      if (account_type_id === 29) {
        if (area_type === 1 && [0, 1].includes(candidate_type)) {
          this.mechanisms = mechanisms2;
        } else if (area_type === 2) {
          if (candidate_type === 0) {
            this.mechanisms = full_time_fixed_salary;
          } else if (candidate_type === 1 || mechanisms2 === 6) {
            this.mechanisms = part_time_fixed_salary;
          }
        }
      }
    },
    async getBusinessLevels() {
      await this.$store.dispatch(GET_BUSINESS_LEVEL, {
        limit: 10000,
        status: 1,
        account_type_id: this.user.profile.account_type_id
      }).then((res) => {
        if (!res.error) {
          this.businessLevels = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getDepartment() {
        if (this.user.use_department) {
            this.$store.dispatch(GET_DEPARTMENT_BY_ID, this.user.use_department.department_id).then((res) => {
                if (!res.error) {
                    this.departmentName = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  }
};
</script>
